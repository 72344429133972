<template>
  <div>
    <template v-if="userStore.user && userStore.user.loginType !== 'IP'">
      <CommonListLabel
        :icon="FaceIconUrl"
        :label="isMm() ? 'Mit Mandag Morgen' : $t('MyAltinget')"
        :translate-label="false"
      />
      <CommonDivider
        :dark="isMm()"
        :class="placement === 'side' ? '' : 'mb-3 md:mb-5'"
      />
      <div class="p-2 rounded-lg border flex items-center">
        <nuxt-img
          class="rounded-full shrink-0 bg-black w-15 h-15 mr-3 overflow-hidden object-cover object-center"
          width="64"
          loading="lazy"
          :src="userImage"
        />
        <div>
          <div class="list-title-xxxs !font-medium">
            {{ userStore.user.name }}
          </div>
          <div class="list-label !font-medium opacity-50 mt-1">
            <NuxtLink :to="menuItems[0].route">
              {{ $t('ShowMyProfile') }}
            </NuxtLink>
          </div>
        </div>
      </div>
      <template v-if="userHasReadingList">
        <div class="mb-2 mt-2 flex justify-between">
          <NuxtLink :to="menuItems[2].route" class="label-m mt-2 opacity-50">
            {{ $t('MySavedArticles') }}
          </NuxtLink>
          <NuxtLink :to="menuItems[2].route">
            <CommonButton>
              {{ $t('ShowAll') }}
            </CommonButton>
          </NuxtLink>
        </div>
        <ListSavedArticles />
      </template>
    </template>
    <template v-else-if="isMm()">
      <ListMostRead />
    </template>
  </div>
</template>

<script setup lang="ts">
import FaceIconUrl from '~/assets/icons/face.svg?url'
import PersonFallbackIconUrl from '~/assets/icons/person-fallback.svg?url'

const { menuItems } = useAccountMenu()

withDefaults(defineProps<{ placement?: string }>(), {
  placement: 'side',
})

const userStore = useUserStore()

const userImage = computed(() => {
  if (!userStore.user?.avatar || userStore.user.avatar === 'avatar_grey.svg')
    return PersonFallbackIconUrl
  return `https://legacy.altinget.dk/images/useravatars/${userStore.user?.avatar}`
})

const userHasReadingList = computed(() => {
  return (userStore.user?.readingList?.length ?? 0) > 0
})
</script>
